#root {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiAlert-root {
    align-items: center;
  }
}
